import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles';
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */
const imageQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  image: {
    width: 120,
    padding: theme.spacing(2),
    boxSizing: "border-box",
    [theme.breakpoints.up('sm')]: {
      width: 200,
    }
  }
}))

function Logo() {
  const classes = useStyles();
  const imageData = useStaticQuery(imageQuery);
  return (
    <Link className={classes.image} to="/">
      <Img fluid={imageData.heroImage.childImageSharp.fluid} />
    </Link>
  );
}

export default Logo
