import React from 'react'
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  footer: {
    flexShrink: 0,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    flexDirection: `column`,
    height: 70,
    background: '#173F5F',
    color: `white`,
    borderTop: `8px solid #20639B`,
    textAlign: `center`,
    [theme.breakpoints.up('sm')]: {
      height: 130,
    }
  },
  link: {
    color: `white`,
  }
}))

function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div>
        <Link className={classes.link} to="/datenschutz">Datenschutz</Link>
        {` | `}
        <Link className={classes.link} to="/impressum">Impressum</Link>
      </div>
      <div>© {new Date().getFullYear()} Stadt Emden</div>
    </footer>
  )
}

export default Footer
