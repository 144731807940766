import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Logo from './Logo';


const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    display: 'flex',
  },
  appBar: {
    background: '#173F5F',
    borderBottom: `8px solid #20639B`,
    height: 70,
    [theme.breakpoints.up('sm')]: {
      height: 130,
    }
  },
  toolbar: {
    height: `100%`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
  }
}))

const Header = ({ siteTitle }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Logo />
        </Toolbar>
      </AppBar>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
