/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './Header'
import Footer from './Footer'
import { makeStyles, ThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import './layout.css'


let theme = createMuiTheme({  palette: {
  primary: {
    main: '#20639B'
  },
  secondary: {
    main: '#ed685f'
  },
},});
theme = responsiveFontSizes(theme);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrapper: {
    flex: 'auto',
    backgroundColor: '#FFF'
  },
  main: {
    display: `block`,
    height: `100%`,
  }
}))

const Layout = ({ children }) => {
  const classes = useStyles();
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <Header siteTitle={data.site.siteMetadata.title} />
            <div className={classes.contentWrapper} >
              <main className={classes.main}>
                {children}
              </main>
            </div>
            <Footer />
          </div>
        </ThemeProvider>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
